<template>
  <footer class="footer" data-testid="TheFooter">
    <div class="container">
      <div class="columns">
        <div class="column is-3">
          <h6>{{ $t('components.footer.follow_us') }}</h6>
          <div class="buttons follow">
            <a
              href="https://twitter.com/hypefactors"
              class="button is-round is-grey"
              target="_blank"
            >
              <span class="icon is-medium">
                <v-icon type="twitter" size="lg" />
              </span>
            </a>
            <a
              href="https://www.facebook.com/hypefactors/"
              class="button is-round is-grey"
              target="_blank"
            >
              <span class="icon is-medium">
                <v-icon type="facebook" size="lg" />
              </span>
            </a>
            <a
              href="https://www.instagram.com/hypefactors_com/"
              class="button is-round is-grey"
              target="_blank"
            >
              <span class="icon is-medium">
                <v-icon type="instagram" size="lg" />
              </span>
            </a>
            <a
              href="https://www.linkedin.com/company/hypefactors"
              class="button is-round is-grey"
              target="_blank"
            >
              <span class="icon is-medium">
                <v-icon type="linkedin" size="lg" />
              </span>
            </a>
          </div>
        </div>
        <div class="column is-3">
          <h6>{{ $t('components.footer.contact') }}</h6>
          <div class="address">
            <i18n
              class="m-b-l"
              path="components.footer.address_parts"
              tag="div"
            >
              <div slot="name">
                <a href="https://hypefactors.com/contact-us"><strong>{{ $t('components.footer.name') }}</strong></a>
              </div>

              <span slot="address">{{ $t('components.footer.street_address') }}<br></span>

              <span slot="postal_code">{{ $t('components.footer.postal_code') }}</span>

              <span slot="situated_at">{{ $t('components.footer.country') }}</span>
            </i18n>

            <a class="telephone contact_item" href="mailto:info@hypefactors.com">
              <span itemprop="email">{{ $t('components.footer.email') }}</span>
            </a>

            <a href="tel:+45 31 15 24 24" class="email contact_item">
              <span itemprop="telephone">{{ $t('components.footer.telephone') }}</span>
            </a>
          </div>
        </div>

        <div class="column is-3">
          <h6>{{ $t('components.footer.pages') }}</h6>
          <ul class="FooterNav">
            <li v-if="!isLoggedIn">
              <a
                :href="$getUrl('for-brands', 'company')"
                active-class="is-active"
                exact
              >
                {{ $t('components.top_nav.for_brands') }}
              </a>
            </li>
            <li v-if="!isLoggedIn">
              <a :href="$getUrl('for-journalists', 'company', 'en')" active-class="is-active">
                {{ $t('components.top_nav.for_journalists') }}
              </a>
            </li>
            <!--<li><router-link :to="{ name: 'pricing' }" active-class="is-active">Pricing</router-link></li>-->
            <!--<li><router-link :to="{ name: 'about' }" active-class="is-active">About</router-link></li>-->
            <li>
              <router-link :to="$i18nRoute({ name: 'terms' })" active-class="is-active">
                {{ $t('components.footer.terms') }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="column is-3">
          <h6>{{ $t('components.footer.follow_the_rev') }}</h6>

          <p>{{ $t('components.footer.join_the_elite') }}</p>

          <a-c-newsletter />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import ACNewsletter from './general/ACNewsletter'

export default {
  components: { ACNewsletter }
}
</script>
<style lang="scss">
@import "~utils";

footer.footer {
  background: white;
  border-top: $hf__border--base;
  padding-top: 65px;
  margin-top: 0px;

  p {
    font-size: 13px;
  }

  h6 {
    color: $grey-dark;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 11px;
    margin: 0 0 17px 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      a {
        font-size: 13px;
        display: block;

        &:hover, &.is-active {
          color: $primary;
        }
      }
    }
  }

  .address {
    font-size: 13px;

    .contact_item {
      display: block;
      margin: 5px 0 0 0;
    }

    .hf-icon {
      height: 15px;
    }
  }
}

</style>
