<template>
  <div class="ACNewsletter">
    <form
      class="columns is-mobile is-aligned-middle"
      data-testid="newsletterSignup"
      novalidate
      @submit.prevent="newsletterSubscribe"
    >
      <div class="column">
        <form-field
          :validator="$v.newsletter.email"
          name="email"
        >
          <input
            v-model="newsletter.email"
            :placeholder="$t('forms.your_email')"
            class="input"
            type="email"
            name="email"
          >
        </form-field>
      </div>
      <div class="column is-narrow">
        <div class="field">
          <p class="control">
            <v-button
              data-testid="newsletterSubmit"
              class="is-primary is-small is-round"
            >
              <span class="icon is-small">
                <v-icon type="chevron-right" />
              </span>
            </v-button>
          </p>
        </div>
      </div>
    </form>
    <transition enter-active-class="animated fadeInDown">
      <p v-if="newsletter.subscribed" class="is-small">
        <strong>{{ $t('components.footer.thank_you_for_subscribing') }}</strong>
      </p>
    </transition>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { loadScript } from '@hypefactors/shared/js/utils'
import { buildUrlWithParams } from '@hypefactors/shared/js/utils/UrlUtils'

/**
 * @module ACNewsletter
 */
export default {
  name: 'ACNewsletter',

  validations: {
    newsletter: {
      email: { required, email }
    }
  },

  data () {
    return {
      newsletter: {
        email: ''
      },
      subscribed: false
    }
  },

  methods: {
    newsletterSubscribe () {
      this.$v.$touch()

      if (this.$v.newsletter.$error) {
        return false
      }
      // AC specific data
      const requiredData = {
        u: 9,
        f: 9,
        s: '',
        c: 0,
        m: 0,
        act: 'sub',
        v: 2,
        jsonp: true
      }
      const data = { ...this.newsletter, ...requiredData }
      // build the URL
      const url = buildUrlWithParams('https://hypefactors.activehosted.com/proc.php', data)
      // load the script into the DOM
      loadScript(url, 'AC-newsletter')
      this.subscribed = true
      this.newsletter.email = ''
    }
  }
}
</script>
